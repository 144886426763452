<template>
  <div class="wrapper">
    <div class="page">
      <div id="main-loading-cover" :class="{'disabled_content':loading}">
        <div class="page-inner" id="main_page">
          <div class="va-card">
            <div class="dash_card_body pad-lr-0 ov-h">
              <div v-if="errors">
                <div class="alert alert-danger">
                  <ul>
                    <li v-for="(err_msg, errindex) in errorMsg" :key="errindex">{{ err_msg }}</li>
                  </ul>
                </div>
              </div>
              <div class="header_section  padd-30 pt-0 pb-0">
                <h4 class="menu_header">Order Details</h4>
                <div class="">
                  <!-- <router-link :to="{ name: 'createmenu' }"> -->
                  <button class="btn btn-default custom_btn custon_orange" data-toggle="modal" data-target=".editOrder" :disabled="user.role.id == UserRoles.waiters ? true : false">
                    <i class="fa fa-plus"></i> Edit Order
                  </button>
                  <!-- </router-link> -->
                </div>
              </div>
              <!-- modal for editing order -->
              <div class="modal fade editOrder" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog order-modal-lg">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h3>Edit Order REF:<b>{{order.channel_display_id}}</b> </h3>
                      <button type="button" class="close button_close" id="button_close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body" style="padding:21px !important">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div v-if="errors ">
                            <div class="alert alert-danger">
                              <ul>
                                <li v-for="(err_msg,errindex) in errorMsg" :key="errindex">{{err_msg}}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <strong>Branch</strong>
                        </div>
                        <div class="col-md-9">
                          <Multiselect v-model="store" @change="searchOrders()" :close-on-select="true" :options="strsoptions" :custom-label="name" :searchable="true" :placeholder="'Stores'" label="name" track-by="name"></Multiselect>

                        </div>
                      </div>
                      <div class="form-group mt-4">
                        <div class="row">
                          <div class="col-md-3">
                            <strong>Order Details</strong>
                          </div>
                          <div class="col-md-9">
                            <div class="form-group ">
                              <div class="row">
                                <div class="col-md-3">
                                  <strong>Name:</strong>
                                </div>
                                <div class="col-md-9">
                                  <input type="text" class="form-control" v-model="name">
                                </div>
                              </div>
                            </div>
                            <div class="form-group ">
                              <div class="row">
                                <div class="col-md-3">
                                  <strong>Phone</strong>
                                </div>
                                <div class="col-md-9">
                                  <input type="text" class="form-control" v-model="phone">
                                </div>
                              </div>
                            </div>
                            <div class="form-group ">
                              <div class="row">
                                <div class="col-md-3">
                                  <strong>Order Type</strong>
                                </div>
                                <div class="col-md-9" v-if="order.order_type !=undefined">
                                  <span> {{ order.order_type }}</span>  &nbsp; &nbsp; &nbsp;
                                  <span v-if="order.order_type.toLowerCase() == 'delivery'">
                                    Change to Pickup <input type="checkbox" v-model="storePickup" value="pickup" > 
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="form-group ">
                              <div class="row">
                                <div class="col-md-3">
                                  <strong>Future Order Date</strong>

                                </div>
                                <div class="col-md-9">
                                  <Datepicker placeholder="Future Date" v-model="futureDate"></Datepicker>
                                </div>
                              </div>
                            </div>
                            <div class="form-group ">
                              <div class="row">
                                <div class="col-md-3">
                                  <strong>More Details</strong>
                                </div>
                                <div class="col-md-9">
                                  <textarea class="form-control" id="" cols="30" rows="10" v-model="address"></textarea>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="form-group mt-2">
                        <div class="row">
                          <div class="col-md-3">
                            <strong>Payment Status</strong>
                          </div>
                          <div class="col-md-9">
                            <span :class="[{'active': paymentStatus.toLowerCase() == 'completed'},{'disabled':paymentStatus.toLowerCase() == 'failed'},{'pending': paymentStatus.toLowerCase() == 'placed'}]"><strong>{{order.payment_status? order.payment_status : 'PENDING'}}</strong></span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group mt-2">
                        <div class="row">
                          <div class="col-md-3">
                            <strong>Order Status</strong>
                          </div>
                          <div class="col-md-9">
                            <span :class="[{'active':orderStatus.toLowerCase() == 'paid'},{'pending': orderStatus.toLowerCase() ===  'created'}]"><strong>{{order.status}}</strong></span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group mt-2">
                        <div class="row">
                          <div class="col-md-3">
                            <strong>Delivery Info</strong>
                          </div>
                          <div class="col-md-9">
                            <span v-if="order.shipping_method !='storepickup'">{{order.map_delivery_address}} <span v-if="order.delivery_address"> <br> <br> <i><strong>More info:</strong> {{order.delivery_address}}</i></span></span>
                            <span v-else>{{order.shipping_method}} from {{order.store}}</span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group mt-2">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="table_section">
                              <table class="table  table-bordered">
                                <thead>
                                  <tr>
                                    <th scope="col">Products</th>
                                    <th scope="col">Subtotal</th>
                                    <th scope="col">Instructions</th>
                                    <th scope="col"></th>

                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="item in orderItems" :key="item.id">
                                    <td>
                                      <span class="text_header">{{item.name}} @{{pesapalConfig.currency_code}} {{item.price_per}} x {{item.quantity}}</span> <br><br>
                                      <span v-if="item.sub_items.length > 0">
                                        <ul class="pl-2">
                                          <li v-for="sub_item in item.sub_items" :key="sub_item.item_id">
                                            <strong>{{sub_item.modifier_group}}:</strong>
                                            {{sub_item.name}} @{{pesapalConfig.currency_code}} {{sub_item.price_per}}
                                          </li>

                                        </ul>
                                      </span>
                                      <span class="pl-2 mt-2" v-if="item.special_instructions !==''"><b>Note</b>:
                                        <span>{{item.special_instructions}}</span></span>
                                    </td>
                                    <td>{{pesapalConfig.currency_code}} {{formatPrice(item.price_total)}}</td>
                                    <td>
                                      <input type="text" class="form-control" v-model="instructions[item.item_id]" :disabled="orderStatus.toLowerCase() == 'created' ? false : true" placeholder="Add Instructions">
                                    </td>
                                    <td>
                                      <div v-if="(orderStatus.toLowerCase() == 'created' && orderItems.length >1)">
                                        <button type="button" @click="removeItem(item.order_item_id)" class="btn btn-danger" title="Remove from order"><span v-if="(removing == item.order_item_id)"><i class='fa fa-spinner fa-spin '></i> Removing</span> <span v-else> <i class="fa fa-times"></i></span> </button>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <th colspan="1" class="text-right">
                                      <strong>Subtotal:</strong>
                                    </th>
                                    <td colspan="3">
                                      {{order.sub_total_price}}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th colspan="1" class="text-right">
                                      <strong>Delivery:</strong>
                                    </th>
                                    <td colspan="3">
                                      {{order.shipping_cost}}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th colspan="1" class="text-right">
                                      <strong>Total:</strong>
                                    </th>
                                    <td colspan="3">
                                      <strong>{{order.total_price}}</strong>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-dgrey" data-dismiss="modal">Close</button>
                      <button type="button" class="btn btn-orange" @click="editOrder()"> <span v-if="updating"><i class='fa fa-spinner fa-spin '></i> Updating ..</span> <span v-else>Update</span> </button>
                    </div>

                  </div>
                </div>
              </div>
              <!-- end -->

              <div class="d-block padd-30 pt-0 pb-0 mb-4">
                <h4 class="menu_header"></h4>
                <div class="back-btn">
                  <router-link :to="{ name: 'recent_orders' }"><i class="fa fa-arrow-left" aria-hidden="true"></i>
                  </router-link>
                  <span>REF: {{ order.channel_display_id }}</span>
                </div>

              </div>

              <hr>

              <div class="row">
                <div class="col-md-4">
                  <div class="Odetails">
                    <h2>Order Details</h2>
                    <ul>
                      <li><strong>Store: </strong>&nbsp;{{order.store}}</li>

                      <li><strong>Order Date: </strong> &nbsp; {{formatDate(order.placed_at,4)}}</li>
                      <li v-if="(order.fargo ==1)">
                        <strong>Future Order</strong> &nbsp; {{formatDate(order.fargo_on,3)}}
                      </li>
                      <li><strong>Created by: </strong>&nbsp; {{order.user !=''?order.user : 'N/A'}}</li>

                      <li><strong>Channel: </strong>&nbsp; {{order.channel}}</li>
                      <li>
                        <strong>Payment Method: </strong>&nbsp; {{order.payment_method ? order.payment_method : 'N/A'}} <br><br>
                        <strong>Payment Status: </strong>&nbsp; <span :class="[{'active': paymentStatus.toLowerCase() === 'completed'},{'disabled':paymentStatus.toLowerCase() === 'failed'},{'pending': paymentStatus.toLowerCase() === 'placed'}]"> <strong>{{order.payment_status? order.payment_status : 'PENDING'}}</strong></span>
                      </li>
                      <li><strong>Order Status: </strong>&nbsp; <span :class="[{'active': orderStatus.toLowerCase() == 'paid'},{'pending': orderStatus.toLowerCase() == 'created'}]"> <strong>{{order.status}}</strong></span> </li>
                    </ul>

                  </div>

                </div>

                <div class="col-md-4">
                  <div class="Odetails">
                    <h2>Customer Details</h2>
                    <ul>
                      <li><strong>Name: </strong>&nbsp; {{order.customer_name}}</li>
                      <li><strong>Phone: </strong>&nbsp; {{order.customer_phone}}</li>
                      <li><strong>Email: </strong>&nbsp; {{order.customer_email}} </li>
                    </ul>

                  </div>

                </div>
                <div class="col-md-4">
                  <div class="Odetails">
                    <h2>Delivery Details</h2>
                    <ul>
                      <li>
                        <strong>Delivery Location: </strong>&nbsp;{{order.shipping_method !='storepickup' ? order.map_delivery_address : order.store}}
                        <span v-if="order.delivery_address">
                          <br><br><small><i><strong>Aditional delivery info:</strong> {{order.delivery_address}}</i></small></span>
                      </li>
                      <li><strong>Delivery Method:</strong>&nbsp; {{order.shipping_method}}</li>
                    </ul>

                  </div>

                </div>

              </div>
              <!-- <div class="Odetails">
                <h2>Order Items</h2>
              </div>              -->
              <div class="dash_card_body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="table_section">
                      <table class="table  table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Products</th>
                            <th scope="col">Qty</th>
                            <th scope="col">Subtotal</th>

                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in order.items" :key="item.item_id">
                            <td>
                              <span class="text_header">{{item.name}} @{{pesapalConfig.currency_code}} {{item.price_per}} x {{item.quantity}}</span> <br><br>
                              <span v-if="item.sub_items.length > 0">
                                <ul class="pl-2">
                                  <li v-for="sub_item in item.sub_items" :key="sub_item.item_id">

                                    <strong>{{sub_item.modifier_group}}:</strong>
                                    {{sub_item.name}} @{{pesapalConfig.currency_code}} {{sub_item.price_per}}
                                  </li>

                                </ul>
                              </span>
                              <div class="pl-2 mt-2">
                                <span v-if="item.special_instructions !==''"><b>Note</b>:
                                  <span>{{item.special_instructions}}</span></span>
                              </div>

                            </td>
                            <td>{{item.quantity}}</td>
                            <td>{{pesapalConfig.currency_code}} {{formatPrice(item.price_total)}}</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th colspan="2" class="text-right">
                              <strong>Subtotal:</strong>
                            </th>
                            <td>
                              {{order.sub_total_price}}
                            </td>
                          </tr>
                          <tr>
                            <th colspan="2" class="text-right">
                              <strong>Delivery:</strong>
                            </th>
                            <td>
                              {{order.shipping_cost}}
                            </td>
                          </tr>
                          <tr>
                            <th colspan="2" class="text-right">
                              <strong>Total:</strong>
                            </th>
                            <td>
                              <strong>{{order.total_price}}</strong>
                            </td>
                          </tr>
                        </tfoot>
                      </table>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="main-loader"></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import useVuelidate from '@vuelidate/core';
//   import { required, email } from '@vuelidate/validators';
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import Multiselect from '@vueform/multiselect';
//   import { useToast } from "vue-toastification";
//   const toast = useToast();

export default {
    components: {
        Multiselect
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            loading: false,
            orderId: this.$route.params.order_id,
            order: [],
            disabled: false,
            stores: [],
            store: '',
            name: '',
            phone: '',
            address: '',
            futureDate: '',
            strsoptions: [],
            paymentStatus: '',
            instructions: [],
            orderItems: [],
            orderStatus: '',
            updating: false,
            removing: false,
            errors: false,
            errorMsg: [],
            storePickup: false
        }
    },

    mounted: async function () {
        // 
        this.getOrder();
        this.getBranches();
    },
    methods: {
        async editOrder() {
            try {
                this.updating = true;
                const itemInstructions = []
                for (const item of Object.values(this.orderItems)) {
                    if (this.instructions[item.item_id] != '') {
                        itemInstructions.push({
                            'item_id': item.item_id,
                            'instruction': this.instructions[item.item_id]
                        })
                    }
                }
                if(this.futureDate !=''){
                  this.futureDate = moment(this.futureDate).format('YYYY-MM-DD h:mm:ss')
                }
                const payload = {
                    'name': this.name,
                    'phone': this.phone,
                    'branch': this.store,
                    'address': this.address,
                    'store_pickup': this.storePickup,
                    'future_date': this.futureDate,
                    'instructions': itemInstructions
                }
                console.log('payload', payload)
                const url = `${this.dashBaseUrl}/update-order-detail/${this.orderId}/${this.merchantID}`
                const response = await this.axios.put(url, payload, this.apiRequestConfig())
                if (response.status == 200) {
                    this.updating = false;
                    this.getOrder();
                    document.getElementById('button_close').click();
                }
            } catch (error) {
                document.getElementById('button_close').click();
                this.updating = false;
                if (error.response.status === 400) {
                    this.errors = true;
                    this.errorMsg = error.response.data.errors
                } else if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            }

        },
        async removeItem(itemId) {
            try {
                this.removing = itemId;
                const url = `${this.dashBaseUrl}/remove-order-item/${itemId}/${this.merchantID}`
                const config = {
                    headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                };
                const response = await this.axios.post(url,{}, config);
                if (response.status == 200) {
                    this.removing = false;
                    this.getOrder();
                }
            } catch (error) {
                this.removing = false;
                if (error.response.status === 400) {
                    this.errors = true;
                    this.errorMsg = error.response.data.errors
                } 
            }
        },
        async getBranches() {
            const url = this.baseUrl + "/branch?merchant_id=" + this.merchantID;
            this.axios.get(url, this.apiRequestConfig()).then((response) => {
                if (response.status === 200) {
                    if (response.data.data !== null) {
                        const stores = response.data.data;
                        for (let i = 0; i < stores.length; i++) {
                            this.strsoptions.push({
                                "value": stores[i].id,
                                "name": stores[i].branch_name
                            })
                        }
                    }
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            })

        },
        async getOrder() {
            const loader = document.getElementById('main-loader');
            loader.style.display = 'block';
            this.loading = true
            try {
                const url = `${this.dashBaseUrl}/order-detail/${this.orderId}/${this.merchantID}`;
                const config = {
                    headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                };
                const response = await this.axios.get(url, config);
                if (response.status == 200) {
                    loader.style.display = 'none';
                    this.loading = false;
                    this.order = response.data.data;
                    this.store = this.order.store_id;
                    this.name = this.order.customer_name;
                    this.phone = this.order.customer_phone;
                    this.address = this.order.delivery_address;
                    this.paymentStatus = this.order.payment_status;
                    this.orderStatus = this.order.status;
                    if (this.order.fargo == 1) {
                        this.futureDate = this.order.fargo_on;
                    }
                    this.orderItems = this.order.items
                    for (const item of Object.values(this.orderItems)) {
                        this.instructions[item.item_id] = item.special_instructions
                    }
                }
            } catch (error) {
                loader.style.display = 'none';
                // 
            }

        }
    },
    mixins: [ReusableDataMixins, ReusableFunctionMixins]
}
</script>
